import React from "react";
import LandingIndex from "./landingIndex";
import LandingPg2 from "./landingPg2";
import SupplementsPg from "./supplementsPg";
import StoryPg from "./storyPg";
import ReviewsPg from "./reviewsPg";
import InstagramFeed from "./instagramFeed";

const Landing = () => {
  return (
    <>
      <LandingIndex />
      <LandingPg2 />
      <SupplementsPg />
      <StoryPg />
      <ReviewsPg />
      <InstagramFeed />
    </>
  );
};

export default Landing;
