import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactStars from "react-stars";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

import styles from "../../styles/components/landing/newReviewModal.module.scss";

import userPlaceholder from "../../images/landing/user-placeholder.png";

const NewReviewModal = (props) => {
  const [rating, setRating] = useState(5);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({});

  // Cloudinary widget
  // const widget = window.cloudinary.createUploadWidget(
  //   {
  // cloudName: "blazen-supplements",
  // uploadPreset: "pywhjlau",
  // folder: "reviews",
  // multiple: false,
  // maxImageWidth: 512,
  //   },
  //   (error, result) => {
  //     if (!error && result && result.event === "success") {
  //       setImage({
  //         url: result.info.url,
  //         thumbnail: result.info.thumbnairl_url,
  //         deleteToken: result.infor.delete_token,
  //       });
  //       console.log(result.info.url);
  //     }
  //   }
  // );

  // const showUploadWidget = () => {
  //   widget.open();
  // };

  // const handleDeleteImage = () => {
  //   setImage({});
  // };

  const showWidget = () => {
    const uploadOptions = {
      cloudName: "blazen-supplements",
      api_key: "674334848918582",
      uploadPreset: "pywhjlau",
      folder: "reviews",
      multiple: false,
      maxImageWidth: 1920,
    };
    const widget = window.cloudinary.createUploadWidget(
      uploadOptions,
      (error, result) => {
        if (!error && result && result.event === "success") {
          setImage({
            url: result.info.url,
            thumbnail: result.info.thumbnail_url,
          });
        }
      }
    );
    widget.open();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const reviewData = {
      name,
      email,
      rating,
      review,
      image: image.url,
    };

    try {
      await axios.post(`${process.env.GATSBY_API_CALL}/review`, reviewData);
      toast.success(`Review submitted successfully!`);
      props.close();
      setRating(5);
      setName("");
      setEmail("");
      setReview("");
      setImage({});
    } catch (e) {
      toast.error(
        `There was an error submitting the reveiw. Please try again later!`
      );
      console.log(`Error: ${e}`);
      // setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={props.show} onHide={props.close} centered>
      <Modal.Header className={styles.header} closeButton />
      <Modal.Body>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Write a <span>Review</span>
          </h1>
          <Form onSubmit={handleSubmit} className="mt-3">
            <Form.Group className="mb-3">
              <div className={styles.imageDiv}>
                <div className={styles.userImage} onClick={showWidget}>
                  <img
                    src={image.url || userPlaceholder}
                    alt="user"
                    height="100%"
                    width="100%"
                  />
                </div>
                <Form.Label>Image</Form.Label>
              </div>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Rating</Form.Label>
              <ReactStars
                count={5}
                size={34}
                value={rating}
                half={false}
                onChange={(e) => setRating(e)}
                className={styles.stars}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Review</Form.Label>
              <Form.Control
                as="textarea"
                rows="7"
                size="lg"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                required
              />
            </Form.Group>
            {
              <Button
                disabled={loading}
                type="submit"
                className={styles.submitBtn}
              >
                {loading ? <FaSpinner className="spinner" /> : "Submit"}
              </Button>
            }
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewReviewModal;
