import React, { useState, useEffect } from "react";
import ReactStars from "react-stars";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { IoMdQuote } from "react-icons/io";
import NewReviewModal from "./newReviewModal";
import axios from "axios";

import styles from "../../styles/components/landing/reviewsPg.module.scss";
import userPlaceholder from "../../images/landing/user-placeholder.png";

const ReviewsPg = () => {
  const [showModal, setShowModal] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(async () => {
    try {
      const reviewsFromDB = await axios.get(
        `${process.env.GATSBY_API_CALL}/reviews`
      );
      setReviews(reviewsFromDB.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Container>
      <NewReviewModal show={showModal} close={() => setShowModal(false)} />
      <div className={styles.body}>
        <h1 className={styles.pgTitle}>Real Reviews by Real People</h1>
        <ReactStars count={5} size={24} edit={false} value={5} />
        <h6 className={styles.reviewCount}>
          Sri Lanka's Favorite Supplement Store
        </h6>
        <IoMdQuote className={styles.quoteIcon} />
        <div className={styles.reviewContainer}>
          {reviews.map((review) => (
            <div className={styles.userReview} key={review._id}>
              <div className={styles.userImage}>
                <img
                  src={review.image || userPlaceholder}
                  alt="user"
                  height="100%"
                  width="100%"
                />
              </div>
              <div>
                <h5 className={styles.userName}>{review.name}</h5>
                {/* <h6 className={styles.userProfession}>Product Manager</h6> */}
              </div>
              <div style={{ marginTop: "-15px", textAlign: "center" }}>
                <ReactStars
                  count={review.rating}
                  size={24}
                  edit={false}
                  value={review.rating}
                />
              </div>
              <p className={styles.reviewDesc}>{review.review} </p>
            </div>
          ))}
        </div>
        <Button
          className={styles.newReviewBtn}
          onClick={() => setShowModal(true)}
        >
          Write a Review
        </Button>
      </div>
    </Container>
  );
};

export default ReviewsPg;
