import React from "react";
import ReactPlayer from "react-player";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Logo from "../logo-white";
import styles from "../../styles/components/landing/landingIndex.module.scss";
import video from "../../videos/vid.mp4";
import Typewriter from "typewriter-effect";

const LandingIndex = () => {
  return (
    <div className={styles.body}>
      <Container>
        <div className={styles.content}>
          <div className={styles.logo}>
            <Logo />
          </div>

          <h1 className={styles.titleMain}>Boost Your</h1>
          <h2 className={styles.titleSub}>
            <Typewriter
              options={{
                strings: ["Performance", "Strength", "Endurance", "Health"],
                autoStart: true,
                loop: true,
              }}
            />
          </h2>
          <Link to="/shop">
            <button className={styles.btn}>Shop Now</button>
          </Link>
        </div>
      </Container>
      <div className={styles.bgVideo}>
        <ReactPlayer
          url={video}
          width="100%"
          height="100%"
          playing={true}
          loop
          muted
        />
      </div>
      <div className={styles.bgTint}></div>
    </div>
  );
};

export default LandingIndex;
