import React from "react";
import ViewNowBtn from "../viewNowBtn";
import Pg2Carousel from "./pg2Carousel";
import Container from "react-bootstrap/Container";

import styles from "../../styles/components/landing/landingPg2.module.scss";

const LandingPg2 = () => {
  return (
    <div className={styles.body}>
      <Container>
        <div className={styles.content}>
          <div className="text-center text-lg-start">
            <h3 className={styles.titleSub}>Factory Direct</h3>
            <h1 className={styles.titleMain}>Supplements</h1>
            <p className={styles.desc}>
              Factory-direct supplements delivered to your doorstep
            </p>
            <ViewNowBtn primary text="Shop Now" link="/shop" />
          </div>
          <div className={styles.carouselDiv}>
            <Pg2Carousel />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LandingPg2;
