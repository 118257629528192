import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { config } from "react-spring";
import loadable from "@loadable/component";

import styles from "../../styles/components/landing/pg2Carousel.module.scss";

const Carousel = loadable(() => import("react-spring-3d-carousel-2"));

const Pg2Carousel = () => {
  const [goToSlide, setGoToSlide] = useState(0);
  const data = useStaticQuery(graphql`
    query {
      allContentfulSupplement(limit: 9) {
        edges {
          node {
            id
            slug
            name
            flavor
            supplier
            productImages {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const slides = data.allContentfulSupplement.edges
    .filter((v, i, a) => a.findIndex((t) => t.node.name === v.node.name) === i)
    .map((item) => {
      return {
        key: item.node.id,
        content: (
          <Link to={`/shop/${item.node.slug}`}>
            <img
              src={item.node.productImages[0].file.url}
              alt={item.node.name}
              className={styles.productImage}
            />
          </Link>
        ),
      };
    })
    .map((slide, index) => {
      return { ...slide, onClick: () => setGoToSlide(index) };
    });

  return (
    <div style={{ width: "100%", height: "100%", margin: "0 auto" }}>
      <Carousel
        slides={slides}
        offsetRadius={2}
        animationConfig={config.slow}
        autoPlay={true}
        interval={2}
      />
    </div>
  );
};

export default Pg2Carousel;
