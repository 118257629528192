import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import { AiFillInstagram } from "react-icons/ai";

import styles from "../../styles/components/landing/instagramFeed.module.scss";

const InstagramFeed = () => {
  const [feedData, setFeedData] = useState([]);

  const postLimit = 12;

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchInstagramPost() {
      try {
        const res = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,caption&limit=${postLimit}&access_token=${process.env.GATSBY_INSTAGRAM_TOKEN}`
        );
        setFeedData(res.data.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchInstagramPost();

    return () => {
      abortController.abort();
    };
  }, []);

  const generateContent = (post) => {
    const { id, caption, media_type, media_url } = post;

    switch (media_type) {
      case "VIDEO":
        return (
          <video
            width="100%"
            height="auto"
            src={media_url}
            type="video/mp4"
            controls
            playsInline
          />
        );
      case "CAROUSEL_ALBUM":
        return (
          <img
            width="100%"
            height="auto"
            id={id}
            src={media_url}
            alt={caption}
          />
        );
      default:
        return (
          <img
            width="100%"
            height="auto"
            id={id}
            src={media_url}
            alt={caption}
          />
        );
    }
  };

  return (
    <div className={styles.body}>
      <Container>
        <a
          href="https://www.instagram.com/blazen_supplements/"
          target="_blank"
          rel="noreferrer"
        >
          <h1 className={styles.handle}>
            <AiFillInstagram /> @blazen_supplements
          </h1>
        </a>
        <div className={styles.feed}>
          {feedData.map((post) => (
            <a
              href={post.permalink}
              target="_blank"
              rel="noreferrer"
              key={post.id}
            >
              <div className={styles.post}>{generateContent(post)}</div>
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default InstagramFeed;
