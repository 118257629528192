import React from "react";
import ReactPlayer from "react-player";
import ViewNowBtn from "../viewNowBtn";
import Container from "react-bootstrap/Container";
import VisibilitySensor from "react-visibility-sensor";

import styles from "../../styles/components/landing/storyPg.module.scss";

const StoryPg = () => {
  return (
    <div className={styles.body}>
      <Container>
        <h1 className={`${styles.pgTitle} mb-0`}>The Blazen Story</h1>
        <p className="text-center">
          Bringing you the best tasting fitness supplements on the planet!
        </p>
        <div className={styles.content}>
          <div className={styles.desc}>
            <div>True Innovation.</div>
            <div>Great Taste.</div>
            <div className="mb-3">Real Results.</div>
            <ViewNowBtn link="/shop" text="Shop Now" />
          </div>
          <div className={styles.video}>
            <VisibilitySensor>
              {({ isVisible }) => (
                <ReactPlayer
                  url="https://youtu.be/QeE95eSPmeg"
                  playing={isVisible ? true : false}
                  controls
                  className="modal__player"
                  width="100%"
                  height="100%"
                  thumbnail="https://i.imgur.com/qZQXQZL.png"
                  onError={(e) => console.log(e)}
                  muted
                />
              )}
            </VisibilitySensor>
          </div>
        </div>
      </Container>
      <div className={styles.bgComponent}></div>
    </div>
  );
};

export default StoryPg;
